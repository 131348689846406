export const FOCUSING = {
  WINDOW: "WINDOW",
  ICON: "ICON",
  DESKTOP: "DESKTOP",
};
export const POWER_STATE = {
  START: "START",
  LOG_OFF: "LOG_OFF",
  TURN_OFF: "TURN_OFF",
};
